body{
  background-color: #FFF;
  font-size: 13px;
}

header {
  position: relative;
  z-index: 400;
}

/* FONT STYLES*/
h3{
  font-size: 28px;
  font-family: 'Open Sans';
  font-weight: 700;
  color: #333333;
  margin-bottom: 25px;
}
h4{
  margin-bottom: 25px;
  font-size: 18px;
  font-family: 'Open Sans',sans-serif;
  font-weight: 300;
}
h5{
  font-size: 14px;
  font-family: 'Open Sans',sans-serif;
  font-weight: 400;
}
/* a, a:link, a:visited{ */
/*   text-decoration: none; */
/*   outline: none; */
/* } */

.row-fluid{
  background: #eaeaea;
}

.blue{
  color: #1f3042;
}
.white{
  color: #fff;
}

.caret {
  border-top: 4px solid #FFFFFF;
  vertical-align: middle;
}

.template-bd {width:100%;background:#fff;padding:0;}

strong{
  font-weight: bold;
}
/* END FONTS STYLES */

/* HEADER */

/* login button */
.btncontainer {
  float: right;
  margin-right: 50px;
}

.loginbg {
  float: right;
  margin-right: 107px;
  width: 173px;
  border-top: 35px solid #1f3042;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
}
.loginbtn {
  background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #90b629), color-stop(1, #64901d) );
  background:-moz-linear-gradient( center top, #90b629 5%, #64901d 100% );
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#90b629', endColorstr='#64901d');
  background-color:#90b629;
  -webkit-border-top-left-radius:9px;
  -moz-border-radius-topleft:9px;
  border-top-left-radius:9px;
  -webkit-border-top-right-radius:9px;
  -moz-border-radius-topright:9px;
  border-top-right-radius:9px;
  -webkit-border-bottom-right-radius:9px;
  -moz-border-radius-bottomright:9px;
  border-bottom-right-radius:9px;
  -webkit-border-bottom-left-radius:9px;
  -moz-border-radius-bottomleft:9px;
  border-bottom-left-radius:9px;
  text-indent:0px;
  border:1px solid #83c41a;
  display:inline-block;
  color:#ffffff;
  font-family: 'Open Sans';
  font-size:15px;
  font-weight: 600;
  font-style:normal;
  height:35px;
  line-height:35px;
  width:155px;
  text-decoration:none;
  text-align:center;
  position: absolute;
  top:0;
  margin-left: 16px;
  margin-top: 5px;

}

.loginbg, .loginbtn {
  display: inline-block;
}

.loginbtn:hover {
  background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #64901d), color-stop(1, #90b629) );
  background:-moz-linear-gradient( center top, #64901d 5%, #90b629 100% );
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#64901d', endColorstr='#90b629');
  background-color:#64901d;
  color: #fff;
}.loginbtn:active {
  position:absolute;
  top:1px;
  margin-left: 16px;
  margin-top: 13px;
}
/* End Login button */

#outer{
 /*  border-top: 15px solid #1f3042; */
  background-color: #fff;
  border-bottom: 1px solid;
  height:34px;
  font-size: x-large;
  font-weight: lighter;
  padding-top: 10px;
  /* Safari 4+, Chrome 1-9 */
 /*  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#fff), to(#e8e8e8));
  Safari 5.1+, Mobile Safari, Chrome 10+
  background-image: -webkit-linear-gradient(top, #fff, #e8e8e8);
  Firefox 3.6+
  background-image: -moz-linear-gradient(top, #fff, #e8e8e8);
  IE 10+
  background-image: -ms-linear-gradient(top, #fff, #e8e8e8);
  Opera 11.10+
  background-image: -o-linear-gradient(top, #fff, #e8e8e8); */
  /*border: 1px solid #fff;*/
}

.navigation {
  clear: both;
}

#header-wrap {
  width: 100%;
  margin: 30 auto;
  padding: 0;
}

#header-wrap h2 a{
  display: block;
  text-indent: -999999px;
  background: url(/mprx-style/images/mainLogo.png) no-repeat;
  background-color: #e8e8e8;
  width: 382px;
  height: 80px;
  float: left;
  margin-left: 110px;
 /*  margin-bottom: 40px;
  margin-top: 10px; */
}

.logoContainer{
	background-color: #e8e8e8;
	height:90px;
	/* margin-top:35px; */
}

#call{
  clear: right;
  float: right;
  padding-right: 75px;
  margin-top: 20px;
}

#help{
  clear: right;
  float: right;
  padding-right: 20px;
  margin-top: 20px;
}
#header-wrap h2 a, #call {
  display: inline-block;
}
#header-wrap h2 a, #help {
  display: inline-block;
}


#call h3{
  margin: 0;
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 38.26px;
  color: #3f5873;

}

#call h4{
  text-align: right;
  margin-bottom: 10px;
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: 23.11px;
  color: 3f5873;
}

#spanhelph3{
  margin: 0;
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 28.26px;
  color: #3f5873;

}

#spanhelph4{
    margin-bottom: 10px;
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: 23.11px;
  color: #3f5873;
}

#nav {
  /*clear: both;
  width: 100%;
  height: 63px;
  background-color: #1f3042;*/
  padding-left: 75px;
}

#nav .menu{
  margin-right: 75px;
}

#nav li {float: left;}
.menu a{
  display: block;
  padding: 0 10px;
  height: 67px;
  line-height: 67px;
  font-size: 14px;
  font-family: 'Open Sans';
  font-weight: 600;
  color: #fff;
  text-decoration: none;
}


/*.group ul li{
  float: left;
  border-right: 1px solid #1f3042;
  border-left: 1px solid #1f3042;
  padding-left: 75px;
}*/

.group {
  clear: both;
  width: 100%;
  height: 63px;
  background-color: #1f3042;
  padding-bottom: 5px;
}
.subGroup {
  clear: both;
  width: 100%;
  height: 13px;
  background-color: #1f3042;
  padding-bottom: 5px;
}

#nav li.last{
  border-left: none;
  border-right: none;
  float: right;
  margin-right: 20px;
}

/*nav ul li a{
  display: block;
  padding: 0 10px;
  height: 62px;
  line-height: 62px;
  font-size: 14px;
  font-family: 'Open Sans';
  font-weight: 600;
  color: #fff;  
}*/

/*nav ul li a:hover{
  background-color: #e8f9ff;
  color: #4f5558;
}*/
.menu:hover {
  background: #e8f9ff;
}

.menu:hover a{
  color: #4f5558;
}

/*sub menu */
#nav ul {
  position: absolute;
  left: -9999px;
  top: -9999px;
  list-style-type: none;
}

.sub-menu a {
  color: #fff;
  white-space: nowrap;
}

.sub-menu > li {
  width: 100%;
  text-align: left;
}

#nav li:hover {position: relative;}
#nav li:hover ul {
  left: 0px;
  top: 67px;
  background: #1f3042;
  padding: 0px;
  color: #fff;
  z-index: 2;
}

#nav .menu:hover ul li a {
  padding-top: 5px;
  display: block;
  /*width: 100%;*/
  /*text-indent: 15px;*/
  color: #fff;
  background-color: #1f3042;
}

#nav .sub-menu:hover a:hover {background-color: #e8f9ff; z-index: 2; color: #4f5558;}

nav ul li div input[type=text]{
  --webkit-border-radius: 5px;
  --moz-border-radius: 5px;
  background: url(../images/searchbtn.jpg) no-repeat 184px #f7f7f7;
  border-radius: 5px;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #1f3042;
  border-top: 1px solid #1f3042;
  color: #4f5558;
  font-family: 'Open Sans';
  font-weight: '400';
  font-style: italic;
  font-size: 13px;
  text-shadow: 1px 1px 0 #c8c8c8;
  margin: 12px 0;
  width: 222px;
  height: 35px;
}

nav ul li div input[type=text]:focus{
  outline: none;
}

::-webkit-input-placeholder {
  color: #c8c8c8;
}

::-moz-placeholder{
  color: #c8c8c8;
}

nav ul li div input[type=submit]{
  background: url(/mprx-style/images/search.png) no-repeat 50% 50%;
  border: none;
  text-indent: -999999px;
  margin-left: 15px;
  height: 50px;
  width: 16px;
}

/* END HEADER */

/*EDITMODE FIXES */
#banner-editmode{
  height:250px;
}

#banner-editmode .dotContentlet {
  width: 200px;
  display: inline-block;
}

#banner-editmode .dotContentlet li {
  height: 200px;
}

.banner {
  position: relative;

  overflow: auto;
  clear: both;
  font-size: 18px;
  line-height: 24px;
  margin-top: -10px;
}
.banner li {
  list-style: none;
  width: 300%;
}
.banner ul li {
  float: left;
  min-height: 400px;
  display: block;

  -o-background-size: 100% 100%;
    -ms-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;

    box-shadow: inset 0 -3px 6px rgba(0,0,0,.1);
}
.banner .dots {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 20px;
                z-index: 2;
        }
                .banner .dots li {
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        margin: 0 4px;

                        text-indent: -999em;

                        border: 2px solid #fff;
                        border-radius: 6px;

                        cursor: pointer;
                        opacity: .4;

                        -webkit-transition: background .5s, opacity .5s;
                        -moz-transition: background .5s, opacity .5s;
                        transition: background .5s, opacity .5s;
                }
                        .banner .dots li.active {
                                background: #fff;
                                opacity: 1;
                        }
.banner .inner {
  position: absolute;
  width: 100%;
  height: 100%;
  color: white;
}

.banner .inner .box {
  /*width:310px;*/
    height:25%;
    position: absolute;
    float: left;
    margin-left: 25px;
    /*position: absolute;*/
    /*background: rgba(0,18,38,.5);*/
    /*opacity: 0.8;*/
    /*filter:alpha(opacity=80);*/
    text-align: center;
    font-family: 'Open Sans';
    z-index: 2;
}



.banner .inner .buttonbox{
  width: 100%;
  height: 10px;
  clear: both;
  background-color: #001226;
  opacity: 0.8;
  filter:alpha(opacity=80);
  position: relative;
  top: 100%;
  margin-top: -10px;
  z-index: 2;
}

.banner .inner .box .symbol {
  font-family: 'Open Sans', sans-serif;
  font-size: 36.54px;
  font-weight: 300;
}

.banner .inner .box .number {
  font-family: 'Open Sans', sans-serif;
  font-size: 53.28px;
  font-weight: 300;
}

.banner .inner .box .crm {
  font-family: 'Open Sans', sans-serif;
  font-size: 28.27px;
  font-weight: 300;
  padding-top: 30px;
  line-height: 1em;
}

.symbol, .number, .crm {
  display: inline-block;
}

.banner .inner .box .bannerLogo{
  background: url(/mprx-style/images/logo.png) no-repeat;
  width: 185px;
  height: 38px;
  margin: 0 auto;
  margin-top: 20px;
}

.getConnected {
  margin-right: 25px;
  left: 70%;
  top: 70%;
  z-index: 2;
  position: absolute;
}

.getConnected .getConnectedTxt {
  font-family: 'Open Sans';
  font-size: 22.93px;
  font-weight: 300;
  padding-bottom: 5px;
}

.btndemo, .txtdemo{
  display: inline-block;
}

.btncase, .txtcase{
  display: inline-block;
}

.banner .inner .box .btndemocontainer, .btncasecontainer {
  position: relative;
}

.btnDemoContainer {
  background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #00afef), color-stop(1, #007ca9) );
  background:-moz-linear-gradient( center top, #00afef 5%, #007ca9 100% );
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#00afef', endColorstr='#007ca9');
  background-color:#00afef;
  -webkit-border-top-left-radius:0px;
  -moz-border-radius-topleft:0px;
  border-top-left-radius:0px;
  -webkit-border-top-right-radius:0px;
  -moz-border-radius-topright:0px;
  border-top-right-radius:0px;
  -webkit-border-bottom-right-radius:0px;
  -moz-border-radius-bottomright:0px;
  border-bottom-right-radius:0px;
  -webkit-border-bottom-left-radius:0px;
  -moz-border-radius-bottomleft:0px;
  border-bottom-left-radius:0px;
  border:1px solid #000000;
  display:inline-block;
  color:#ffffff;
  font-family: 'Open Sans';
  font-size:15px;
  font-weight:normal;
  font-style:normal;
  height:40px;
  line-height:36px;
  width:137px;
  text-decoration:none;
  text-align:center;
}
.btnDemoContainer:hover {
  background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #007ca9), color-stop(1, #00afef) );
  background:-moz-linear-gradient( center top, #007ca9 5%, #00afef 100% );
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#007ca9', endColorstr='#00afef');
  background-color:#007ca9;
  color: #fff;
}.btnDemoContainer:active {
  position:relative;
  top:1px;
}

.btnCaseContainer {
  background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #9ccd29), color-stop(1, #618f1d) );
  background:-moz-linear-gradient( center top, #9ccd29 5%, #618f1d 100% );
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#9ccd29', endColorstr='#618f1d');
  background-color:#9ccd29;
  -webkit-border-top-left-radius:0px;
  -moz-border-radius-topleft:0px;
  border-top-left-radius:0px;
  -webkit-border-top-right-radius:0px;
  -moz-border-radius-topright:0px;
  border-top-right-radius:0px;
  -webkit-border-bottom-right-radius:0px;
  -moz-border-radius-bottomright:0px;
  border-bottom-right-radius:0px;
  -webkit-border-bottom-left-radius:0px;
  -moz-border-radius-bottomleft:0px;
  border-bottom-left-radius:0px;
  text-indent:0px;
  border:1px solid #000000;
  display:inline-block;
  color:#ffffff;
  font-family: 'Open Sans';
  font-size:11px;
  font-weight:normal;
  font-style:normal;
  height:40px;
  line-height:40px;
  width:165px;
  text-decoration:none;
  text-align:center;
}
.btnCaseContainer:hover {
  background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #618f1d), color-stop(1, #9ccd29) );
  background:-moz-linear-gradient( center top, #618f1d 5%, #9ccd29 100% );
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#618f1d', endColorstr='#9ccd29');
  background-color:#618f1d;
  color: #fff;
}.btnCaseContainer:active {
  position:relative;
  top:1px;
}

.btndemocontainer .btndemo .btnText {
  margin-left: 34px;
  display: inline-block;
  font-family: 'Open Sans';
  color: white;
  font-size: 30.49px;
  padding-top: 19px;
}

.btndemocontainer .btndemo .btnText p, .btnTextCases p {
  display: inline-block;
}

.btncasecontainer .btncase .btnTextCases{
  margin-left: 20px;
  display: inline-block;
  font-family: 'Open Sans';
  color: white;
  font-size: 17.49px;
  padding-top: 19px;
}

.textSemiBold {
  font-weight: 600;
}

.textBold{
  font-weight: 700;
}

.textLight{
  font-weight: 300;
}

.slideText {
  color: #FFFFFF;
  float: left;
  padding-left: 50px;
  padding-top: 130px;
  text-align: left;
  width: 90%;
}

#Solutions {
  background: #e9faff;
  width: 100%;
  font-family: 'Open Sans';
  position: relative;
  color: black;
  padding-bottom: 20px;
}

#Solutions .title {
  font-size: 31px;
  font-weight: 400;
  padding-left: 25px;
  padding-top: 20px;
  text-align: left;
  float: left;
  padding-bottom: 18px;
}

#Solutions .line, #company .line {
  height: 1px;
  border-color: #8c8c8c;
  clear: both;
  margin-left: 25px;
  margin-right: 25px;
}

.inline-block {
  display: inline-block;
}

/*Edit mode fixes*/
#groupsol-editmode .dotContentlet{
  padding-left: 65px;
  padding-top: 20px;
  display: inline-block;
  width: 200px;
}

#groupsol {
  margin-left: 25px;
  margin-right: 25px;
}

.innerbox-solution{
  margin: 0 auto;
}

#groupsol .solution-block, #groupsol-editmode .solution-block {
  float: left;
  position: relative;
  text-align: left;
  /*width: 250px;*/
}

#groupsol .block-title, #groupsol-editmode .block-title{
  font-weight: 400;
  font-size: 17px;
  padding-bottom: 11px;
}

#groupsol .block-body, #groupsol-editmode .block-body {
  font-weight: 400;
  font-size: 13px;
  padding-top: 20px;
  line-height: 1.4em;
  text-align: justify;
}

#groupsol .readbtn, #company .readbtn, #groupsol-editmode .readbtn {
  float: right;
  margin-top: 10px;
}

.block-btn, #company .block-btn, #groupsol-editmode .block-btn {
  background-color:#004369;
  -webkit-border-top-left-radius:4px;
  -moz-border-radius-topleft:4px;
  border-top-left-radius:4px;
  -webkit-border-top-right-radius:4px;
  -moz-border-radius-topright:4px;
  border-top-right-radius:4px;
  -webkit-border-bottom-right-radius:4px;
  -moz-border-radius-bottomright:4px;
  border-bottom-right-radius:4px;
  -webkit-border-bottom-left-radius:4px;
  -moz-border-radius-bottomleft:4px;
  border-bottom-left-radius:4px;
  text-indent:0;
  display:inline-block;
  color:#fff;
  font-family: Open Sans;
  font-size:11px;
  font-weight:bold;
  font-style:normal;
  height:35px;
  line-height:35px;
  width:100px;
  text-decoration:none;
  text-align:center;
}
#groupsol .block-btn:hover , #company .block-btn:hover{
  background-color:#0a73ab;
}
#groupsol .block-btn:active, #company .block-btn:active {
  position:relative;
  top:1px;
}

#company .info {
  /*width: 718px;*/
  padding-left: 75px;
  padding-right: 55px;
  float: left;
}

#company .title {
  font-size: 31px;
  font-family: 'Open Sans';
  padding-top: 52px;
  text-align: left;
  float: left;
  padding-bottom: 18px;
}

#company .subtitle {
  font-weight: 400;
  font-family: 'Open Sans';
  font-size: 23px;
  text-align: left;
  padding-top: 0px;
  padding-bottom: 20px;
}

#company .innertext, .videotext > p {
  font-weight: 400;
  font-size: 13px;
  text-align: justify;
  line-height: 2em;
}

#company .title .light {
  font-weight: 300;
}

#company .title .bold {
  font-weight: 700;
}

#company .title .subtitle {
  font-weight: 400;
  font-size: 23px;
}

#company .videobody {
  float: left;
  text-align: left;
}

#company .video {
  float: left;
  padding-right: 20px;
}


#company .readbtn {
  margin-bottom: 43px;
}

#company .line {
  margin-left: 0px;
  margin-right: 0px;
}

#company .sidebar {
  margin-top: 0 auto 0 50px;
  float: left;
}

#company .sidebar .requestDemo {
  background: url(../images/requestdemo-bg.jpg) no-repeat;
  height: 140px;
  width: 310px;
}

#company .sidebar .requestDemo .innerText {
  padding-top: 25px;
  font-family: 'Open Sans';
  font-size: 19px;
  font-weight: 600;
  color: #082a60;
  line-height: 1.2em;
}

#company .sidebar .whitePapers {
  background: url(../images/bg1.jpg) no-repeat;
  height: 125px;
  width: 310px;
}

#company .sidebar .callToday {
  background: url(../images/giveuscall-bg.jpg) no-repeat;
  height: 125px;
  width: 310px;
}

#company .sidebar .topics {
  background: url(../images/bg1.jpg) no-repeat;
  height: 140px;
  width: 310px;
}

.sidebarbox {
  padding-bottom: 10px;
}

.tail {
  width: 100%;
  height: 24px;
  clear: both;
  background-color: #001226;
    opacity: 0.8;
    filter:alpha(opacity=80);
    margin-top: 45px;
}

.tailWhitePapers {
  background-color: #e94f18;
  margin-top: 50px;
}

.tailCallToday {
  background-color: #384d19;
  margin-top: 49px;

}

.tailTopics {
  background-color: #17b1f1;
  margin-top: 48px;
}

.whitePaperstext {
  font-family: 'Open Sans';
  color: #d94814;
  font-weight: 600;
  font-size: 18px;
  padding-top: 15px;
}

.whitePapersminustext{
  color:black;
  font-weight: 600;
  font-size: 12px;
  padding-top: 5px;
}

.callTodayText{
  color: #4e760d;
  font-size: 21px;
  font-weight: 600;
  padding-top: 30px;
}

.topicsText {
  color: #115a78;
  font-size: 20px;
  font-weight: 600;
  padding-top: 15px;
}

.requestBtn {
  background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #00b1f2), color-stop(1, #007dab) );
  background:-moz-linear-gradient( center top, #00b1f2 5%, #007dab 100% );
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#00b1f2', endColorstr='#007dab');
  background-color:#00b1f2;
  -webkit-border-top-left-radius:0px;
  -moz-border-radius-topleft:0px;
  border-top-left-radius:0px;
  -webkit-border-top-right-radius:0px;
  -moz-border-radius-topright:0px;
  border-top-right-radius:0px;
  -webkit-border-bottom-right-radius:0px;
  -moz-border-radius-bottomright:0px;
  border-bottom-right-radius:0px;
  -webkit-border-bottom-left-radius:0px;
  -moz-border-radius-bottomleft:0px;
  border-bottom-left-radius:0px;
  text-indent:0;
  border:1px solid #004369;
  display:inline-block;
  color:#ffffff;
  font-family:Arial;
  font-size:13px;
  font-weight:bold;
  font-style:normal;
  height:40px;
  line-height:40px;
  width:235px;
  text-decoration:none;
  text-align:center;
  left: 10%;
  z-index: 2;
  font-family: 'Open Sans';
  position: absolute;
  margin-top: 15px;
}
.requestBtn:hover {
  background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #007dab), color-stop(1, #00b1f2) );
  background:-moz-linear-gradient( center top, #007dab 5%, #00b1f2 100% );
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#007dab', endColorstr='#00b1f2');
  background-color:#007dab;
  color: #fff;
}.requestBtn:active {
  position:absolute;
  margin-top:14px;
}

.boxfooter {
  position: relative;
  width: 100%;
  float: left;
}

.whitePapersBtn {
  background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #ff9900), color-stop(1, #ff5100) );
  background:-moz-linear-gradient( center top, #ff9900 5%, #ff5100 100% );
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff9900', endColorstr='#ff5100');
  background-color:#ff9900;
  -webkit-border-top-left-radius:0px;
  -moz-border-radius-topleft:0px;
  border-top-left-radius:0px;
  -webkit-border-top-right-radius:0px;
  -moz-border-radius-topright:0px;
  border-top-right-radius:0px;
  -webkit-border-bottom-right-radius:0px;
  -moz-border-radius-bottomright:0px;
  border-bottom-right-radius:0px;
  -webkit-border-bottom-left-radius:0px;
  -moz-border-radius-bottomleft:0px;
  border-bottom-left-radius:0px;
  text-indent:0;
  border:1px solid #004369;
  display:inline-block;
  color:#ffffff;
  font-family:Arial;
  font-size:13px;
  font-weight:bold;
  font-style:normal;
  height:40px;
  line-height:40px;
  width:235px;
  text-decoration:none;
  text-align:center;
  left: 10%;
  z-index: 2;
  font-family: 'Open Sans';
  position: absolute;
  margin-top: 20px;
}
.whitePapersBtn:hover {
  background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #ff5100), color-stop(1, #ff9900) );
  background:-moz-linear-gradient( center top, #ff5100 5%, #ff9900 100% );
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5100', endColorstr='#ff9900');
  background-color:#ff5100;
  color: #fff;
}.whitePapersBtn:active {
  position:absolute;
  margin-top: 19px;
}

.callTodayBtn {
  background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #91b729), color-stop(1, #67911f) );
  background:-moz-linear-gradient( center top, #91b729 5%, #67911f 100% );
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#91b729', endColorstr='#67911f');
  background-color:#91b729;
  -webkit-border-top-left-radius:0px;
  -moz-border-radius-topleft:0px;
  border-top-left-radius:0px;
  -webkit-border-top-right-radius:0px;
  -moz-border-radius-topright:0px;
  border-top-right-radius:0px;
  -webkit-border-bottom-right-radius:0px;
  -moz-border-radius-bottomright:0px;
  border-bottom-right-radius:0px;
  -webkit-border-bottom-left-radius:0px;
  -moz-border-radius-bottomleft:0px;
  border-bottom-left-radius:0px;
  text-indent:0;
  border:1px solid #004369;
  display:inline-block;
  color:#ffffff;
  font-family:Arial;
  font-size:13px;
  font-weight:bold;
  font-style:normal;
  height:40px;
  line-height:40px;
  width:235px;
  text-decoration:none;
  text-align:center;
  left: 10%;
  z-index: 2;
  font-family: 'Open Sans';
  position: absolute;
  margin-top: 20px;
}
.callTodayBtn:hover {
  background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #67911f), color-stop(1, #91b729) );
  background:-moz-linear-gradient( center top, #67911f 5%, #91b729 100% );
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#67911f', endColorstr='#91b729');
  background-color:#67911f;
  color: #fff;
}.callTodayBtn:active {
  position:absolute;
  margin-top: 19px;
}

.topicsBtn {
  background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #47bbec), color-stop(1, #135c7b) );
  background:-moz-linear-gradient( center top, #47bbec 5%, #135c7b 100% );
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#47bbec', endColorstr='#135c7b');
  background-color:#47bbec;
  -webkit-border-top-left-radius:0px;
  -moz-border-radius-topleft:0px;
  border-top-left-radius:0px;
  -webkit-border-top-right-radius:0px;
  -moz-border-radius-topright:0px;
  border-top-right-radius:0px;
  -webkit-border-bottom-right-radius:0px;
  -moz-border-radius-bottomright:0px;
  border-bottom-right-radius:0px;
  -webkit-border-bottom-left-radius:0px;
  -moz-border-radius-bottomleft:0px;
  border-bottom-left-radius:0px;
  text-indent:0;
  border:1px solid #004369;
  display:inline-block;
  color:#ffffff;
  font-family:Arial;
  font-size:13px;
  font-weight:bold;
  font-style:normal;
  height:40px;
  line-height:40px;
  width:235px;
  text-decoration:none;
  text-align:center;
  left: 10%;
  z-index: 2;
  font-family: 'Open Sans';
  position: absolute;
  margin-top: 20px;
}
.topicsBtn:hover {
  background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #135c7b), color-stop(1, #47bbec) );
  background:-moz-linear-gradient( center top, #135c7b 5%, #47bbec 100% );
  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#135c7b', endColorstr='#47bbec');
  background-color:#135c7b;
}.topicsBtn:active {
  position:absolute;
  margin-top: 19px;
}

/**********/
  /* Footer */
  /**********/
  #footer_container {
    position:abosolute;
    width:100%;
    background: #eaeaea;
    float:left;
  }
  #slider_footer {
    position:abosolute;
    width:100%;
    height:150px;
    float:left;
    background-color: #FFF;
  }
  #footer_items_container {
    position:abosolute;
    width:100%;
   /*  height:200px; */
    float:left;
    background-color: #1F3042;

  }

  #logo_footer_bottom
  {
    position:relative;
    width:440px;
    height:216px;
    float:left;
    background-color: #1F3042;
    background: url(/mprx-style/images/logofooter1.png) no-repeat;
  }
  #logo_footer
  {
    position:relative;
    width:440px;
    height:180px;
    float:left;
    background-color: #1F3042;
  }

  #social_footer_bottom
  {
    position:relative;
    /* width: 600px; */
    float:left;
  }

  .fileHeader {
    padding-top: 55px;
    padding-bottom: 20px;
  }

  #footerMenuTable{
    text-align: right;
    float: right;
    padding-right: 30px;
  }
  #footerMenuTable .header-table{
    height: 45px;
    color: black;
    font-size: 16px;
    padding: 10px;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }
  .columnMenuFooter{
    font-family: 'Open Sans',sans-serif;
    color: white;
    font-size: 14px;
    height:20px;
    display: inline-block;
    margin-left: 25px;
    text-align: center;
    font-weight: 400;
  }
  #btncontainer .columnSocialFacebook{
    width: 35px;
    height:37px;
    color: white;
    display: inline-block;
    text-align: center;
    background: url(/mprx-style/images/facebook.jpg) no-repeat;
    background-size:100% 80%;
  }
  #btncontainer .columnSocialTwitter{
    width: 30px;
    height:37px;
    color: white;
    display: inline-block;
    text-align: center;
    background: url(/mprx-style/images/twitterN.jpg) no-repeat;
    background-size:100% 80%;
  }
  #btncontainer .columnSocialIn{
    width: 35px;
    height:37px;
    color: white;
    display: inline-block;
    text-align: center;
    background: url(/mprx-style/images/linkedin.jpg) no-repeat;
    background-size:100% 80%;
  }
  #btncontainer .columnSocialsearch{
    width: 35px;
    height:37px;
    color: white;
    display: inline-block;
    text-align: center;
    background: url(/mprx-style/images/searchN.png) no-repeat;
    background-size:100% 80%;
  }
  #btncontainer a:hover{
    text-decoration:none;
  }
  #footerMenuTable .columnCopyright{
    font-family: 'Open Sans',sans-serif;
    color: white;
    width: 100%;
    display: inline-block;
    text-align: right;
    font-weight: 400;
  }

  #footerMenuTable .termsFooter{
    font-family: 'Open Sans',sans-serif;
    color: white;
    font-size: 13px;
    height:20px;
    display: inline-block;
    margin-left: 5px;
    text-align: center;
    font-weight: 350;
  }

  .case_studies{
    position:relative;
    width:306px;
    height:358px;
    float:left;
    margin:5px 25px 1px 5px;

  }
  .case_studies .title_box {
    width: 306px;
    height: 63px;
    position: relative;
    float:left;
    background-color:#1f3042;
    margin:0px 0px 0px 0px;


  }

  .title_box .title {
    font-size: 18px;
      font-family: 'Open Sans';
    color : #f7ff70;
    text-align: center;
    line-height:35px;
  }

  .title_box .subtitle {
    font-size: 12px;
      font-family: 'Open Sans';
    color : white;
    text-align: center;
  }

  .info_box {
    width: 306px;
    height: 292px;
    position: relative;
    float:left;
    background-image: -ms-linear-gradient(left, #FFFFFF 0%, #DEEDEF 100%);
    background-image: -moz-linear-gradient(left, #FFFFFF 0%, #DEEDEF 100%);
    background-image: -o-linear-gradient(left, #FFFFFF 0%, #DEEDEF 100%);
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0, #FFFFFF), color-stop(1, #DEEDEF));
    background-image: -webkit-linear-gradient(left, #FFFFFF 0%, #DEEDEF 100%);
    background-image: linear-gradient(to right, #FFFFFF 0%, #DEEDEF 100%);
    margin:0px 0px 0px 0px;


  }
  .box_download{
    position: relative;
    float:left;
    border-style:dotted solid;
    width: 281px;
    height: 96px;
    margin:27px 14px 27px 14px; !important;

  }
  .box_download_image{
    position: relative;
    float:left;
    width: 91px;
    height: 82px;
  }

  .box_pdf_text_button{
    position: relative;
    float:left;
    width: 185px;
    height: 82px;
  }
  .box_text_button{
    position: relative;
    float:left;
    width: 185px;
  }
  .recent_data{
    position:relative;
    width:306px;
    height:358px;
    float:left;
    margin:5px 25px 1px 5px;

  }
  .recent_data .rd_title_box{
    width: 306px;
    height: 63px;
    position: relative;
    float:left;
    background-color:#6a002b;
    margin:0px 0px 0px 0px;


  }
  .rd_title_box .rd_title {
    font-size: 18px;
      font-family: 'Open Sans';
    color : #f7ff70;
    text-align: center;
    line-height:35px;
  }

  .rd_title_box .rd_subtitle {
    font-size: 12px;
      font-family: 'Open Sans';
    color : white;
    text-align: center;
  }
.button_download{
   border:1px solid #df0909; -webkit-border-radius: 3px; -moz-border-radius: 3px;border-radius: 3px;font-size:12px;font-family:arial, helvetica, sans-serif; padding: 1px 15px 1px 15px;       text-decoration:none; display:inline-block;text-shadow: -1px -1px 0 rgba(0,0,0,0.3);font-weight:bold; color: #FFFFFF;
   background-color: #f62b2b; background-image: -webkit-gradient(linear, left top, left bottom, from(#f62b2b), to(#d20202));
   background-image: -webkit-linear-gradient(top, #f62b2b, #d20202);
   background-image: -moz-linear-gradient(top, #f62b2b, #d20202);
   background-image: -ms-linear-gradient(top, #f62b2b, #d20202);
   background-image: -o-linear-gradient(top, #f62b2b, #d20202);
   background-image: linear-gradient(to bottom, #f62b2b, #d20202);filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#f62b2b, endColorstr=#d20202);
 }

 .button_download:hover{
   border:1px solid #b30808;
   background-color: #e40a0a; background-image: -webkit-gradient(linear, left top, left bottom, from(#e40a0a), to(#9f0202));
   background-image: -webkit-linear-gradient(top, #e40a0a, #9f0202);
   background-image: -moz-linear-gradient(top, #e40a0a, #9f0202);
   background-image: -ms-linear-gradient(top, #e40a0a, #9f0202);
   background-image: -o-linear-gradient(top, #e40a0a, #9f0202);
   background-image: linear-gradient(to bottom, #e40a0a, #9f0202);filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#e40a0a, endColorstr=#9f0202);
 }

.span4 > div {
  margin-top: 50px;
}

.footer-title .textSemiBold {
  font-family: 'Open Sans';
  font-size: 29px;
  color: #4f4f4f;
}

.footer-title {
  float: left;
  margin-bottom: 40px;
  margin-left: 75px;
}

.footer-title .textLight {
  font-family: 'Open Sans';
  font-size: 21px;
  color: #426683;
}

.box_supertitle{
  color: #1F3042;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Open Sans',sans-serif;
  margin-left: 5px;
}

.box_pics {
    float: left;
    margin: 1px 0 10px 5px;
    position: relative;
    width: 175px;
}

.box_title{
  color: #1F3042;
  font-family: serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 1;
  height: 50px;
    }
.box_title_sidebar{
  color: #1F3042;
  font-family: serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 1;
  margin-left: 10px;
  margin-bottom: 10px;
}
 .box_request {
    float: left;
    height: 85px;
    margin: 1px 1px 1px 5px;
    position: relative;
    width: 290px;
  }
  .box_text {
    color: #333333;
    font-family: 'Open Sans',sans-serif;
    font-size: 12px;
    font-weight: 600;
    height: 110px;
    width: 175px;
    margin:5px 15px 5px 5px;
  }

.box_text_video {
    color: #1F3042;
  font-family: serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 1;
    width: 300px;
    margin:5px 15px 5px 5px;
  }

.style_div {
  font-family: 'Open Sans';
  font-weight: 300;
  line-height: 1.5em;
  margin: 15px 75px;
}

.box_partners {
    float: left;
    height: 1069px;
    margin: 1px 0 0;
    position: relative;
    width: 765px;
}

.box_partners_pics {
    float: left;
    height: 137px;
    margin: 1px 0 0 5px;
    position: relative;
    width: 175px;
}


.box_partners_label {
    float: left;
    height: 137px;
    margin: 1px 20px 0 5px;
    position: relative;
    width: 175px;
    color: #333333;
    font-family: 'Open Sans',sans-serif;
    font-size: 14px;
    font-weight: 600;
}
#Resources {
 float: left;
  height: 600px;
  margin: 1px 0 0;
  position: relative;
  width: 720px;
}

.partnerImage img {
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  vertical-align: middle;
}

.partnerLabel {
  font-size: 14px;
  font-weight: 600;
}

.partnerHelper {
  vertical-align: middle;
  position: relative;
  line-height: 145px;
}

.partnerImage{
  height: 145px;
}

.siteMap ul li {
  border-left: 1px solid #CCCCCC;
  border-top: 1px solid #CCCCCC;
  display: block;
  float: left;
  font-size: 24px;
  margin: 20px 0 20px 30px;
  min-height: 150px;
  padding: 4px 0 4px 25px;
  width: 177px;
  font-weight: 600;
}

.siteMap ul li ul li {
  background: url("/application/themes/mevesitheme/images/icons/bullet-1-sub.gif") no-repeat scroll left 6px rgba(0, 0, 0, 0);
  border: 0 none;
  font-size: 16px;
  margin: 19px 0 0 4px;
  min-height: 10px;
  padding: 0 0 0 10px;
  text-align: left;
  width: 160px;
  text-align: left;
  font-weight: 400;
}
.box_pics > img {
  height: 118px;
  width: 100%;
}

	#box_main{
	    float: left;

	    /* margin: 1px 0 0; */
	    position: relative;
	    width: 99.8%;
		height: 500px;
	    float: left;
	    border:1px solid;

		/* background: rgba(212,228,239,1);
		background: -moz-linear-gradient(top, rgba(212,228,239,1) 0%, rgba(134,174,204,1) 100%);
		background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(212,228,239,1)), color-stop(100%, rgba(134,174,204,1)));
		background: -webkit-linear-gradient(top, rgba(212,228,239,1) 0%, rgba(134,174,204,1) 100%);
		background: -o-linear-gradient(top, rgba(212,228,239,1) 0%, rgba(134,174,204,1) 100%);
		background: -ms-linear-gradient(top, rgba(212,228,239,1) 0%, rgba(134,174,204,1) 100%);
		background: linear-gradient(to bottom, rgba(212,228,239,1) 0%, rgba(134,174,204,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d4e4ef', endColorstr='#86aecc', GradientType=0 );*/
	}
    #box_content {
	  background-color: #fff;
	  bottom: 0;
	  /* box-shadow: 0 10px 6px -6px #000; */
	  height: 200px;
	  left: 0;
	  margin: auto;
	  position: absolute;
	  right: 0;
	  top: 30px;
	  width: 100px;
	  float: right;
	}

	#box_content_inactive {
	    background-color: #fff;
	    height: 200px;
	    margin: auto;
	}

	#image_content{
	   width: 530px;
	   float: left;
	   height:500px;
	   border-right: 1px solid;
	   background-color: rgb(51,63,79);
	}
	#form_login {
	  height: 230px;
	  width: 370px;
/* 	  top: 50px; */
/* 	  left:620px; */
	  background-color: #FFF;
	  -webkit-box-shadow: 0 10px 6px -6px #777;
	  -moz-box-shadow: 0 10px 6px -6px #777;
	  box-shadow: 0 10px 6px -6px #777;
	  position:relative;
	}

	#form_answer {
	  height: 190px;
	  width: 100%;
	  top: 60px;
	  left:10px;
	  background-color: #FFF;
	  -webkit-box-shadow: 0 10px 6px -6px #777;
	  -moz-box-shadow: 0 10px 6px -6px #777;
	  box-shadow: 0 10px 6px -6px #777;
	  position:relative;
	}


     #signInLabel {
		  color: #2a5996;
		  font-size: 23px;
		  padding-bottom: 20px;
		  padding-left: 20px;
		  text-align: left;
     }
      .loginformlabel {
          font: bold 1.2em "Trebuchet MS", Arial, Helvetica, sans-serif;
          color: #2a5996;
          width: 80px;
          padding-right: 5px;
          padding-bottom: 10px;
          padding-left:20px;
      }

      .loginforminput {
          background: transparent;
          width: 190px;
          padding: 5px;
          margin-bottom: 10px;
          padding-right: 145px;
      }

      .inputtextbox {
          font: normal 0.8em "Trebuchet MS", Arial, Helvetica, sans-serif;
          color: #2a5996;
          width: 250px;
      }

	#boxlinks{
		height: 230px;
		width: 450px;
/* 		top: 50px; */
/* 		left:120px; */
		background-color: #FFF;
		-webkit-box-shadow: 0 10px 6px -6px #777;
		-moz-box-shadow: 0 10px 6px -6px #777;
		box-shadow: 0 10px 6px -6px #777;
		position: absolute;

	}
	#loginformForgot {
          background-color: #FFF;
		margin:5px 5px 5px 5px;
	 	display:block;
	 	width:45%;
	 	float:left;
	 	height: 215px;
/* 	 	top: 50px; */
/* 	 	left:50%px; */
	 	 margin-top: 5px;
	 	 margin-left: 25%;
		-webkit-box-shadow: 0 10px 6px -6px #777;
		-moz-box-shadow: 0 10px 6px -6px #777;
		box-shadow: 0 10px 6px -6px #777;

		border-radius: 5px;
    	border: 1px solid #4D6DAD;
/*     	 position: relative; */

      }
	#loginform {
/*           background-color: #FFF; */
/* 		margin:5px 5px 5px 5px; */
/* 	 	display:block; */
/* 	 	width:40%; */
/* 	 	float:left; */
/* 	 	height: 215px; */

/* 	 	 margin-top: 5px; */
/* 	 	 margin-left: 25px; */
/* 		-webkit-box-shadow: 0 10px 6px -6px #777; */
/* 		-moz-box-shadow: 0 10px 6px -6px #777; */
/* 		box-shadow: 0 10px 6px -6px #777; */

/* 		border-radius: 5px; */
/*     	border: 1px solid #4D6DAD; */
    	padding-top:105px;
    	padding-left:10px;

      }
	#boxlinksContent{
		background-color: #DCDCDC;
		height: 100%;
/* 		margin:5px 5px 5px 5px; */
/* 	 	display:block; */
/* 	 	width:55%; */
/* 	 	float:left; */
/* 	 	border-radius: 5px; */
/* 	 	margin-top: 5px; */
/* 	 	margin-left: 5px; */
/* 		-webkit-box-shadow: 0 10px 6px -6px #777; */
/* 		-moz-box-shadow: 0 10px 6px -6px #777; */
/* 		box-shadow: 0 10px 6px -6px #777; */

	}

#spanh3{
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 25px;
  color: #3f5873;
  margin-bottom: 20px;
}
.center-text {
	text-align: center;
}
#spanh4{
   margin-top: 10px;
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: 21px;
  color: #3f5873;
}
#spanh5{
  margin-bottom: 10px;
  margin-left: 20px;
  font-family: 'Open Sans';
  font-weight: 150;
  font-size: 16.11px;
  color: #1E90FF;
}

#links{
	height: 170px;
}

.community{
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: 21px;
  color: #3f5873;
  margin-left: 20px;
}


.panel {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: 1px solid #e1e6ef;
  margin-bottom: 30px;
}
.panel .panel-heading {
  line-height: 40px;
  padding: 0 15px;
  min-height: 40px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  border-color: #e1e6ef;
}
.panel .panel-heading .panel-title {
  line-height: 40px;
}
.panel .panel-heading h2 {
  float: left;
  padding: 10px 0px;
  margin: 0px 0px 0px 10px;
  color: #374767;
}
.panel .panel-heading h2 i {
  color: #e1e6ef;
  padding: 12px 0px;
  height: 40px;
  width: 40px;
  display: inline-block;
  text-align: center;
  margin: -10px 0px -10px -20px;
  font-size: 16px;
}
.panel .panel-heading .panel-actions {
  float: right;
  margin-right: -15px;
}
.panel .panel-heading .panel-actions i {
  display: inline-block;
  color: #e1e6ef;
  text-align: center;
  height: 40px;
  width: 40px;
  padding: 12px 0px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  text-decoration: none;
}
.panel .panel-heading .panel-actions a {
  margin-left: -3px !important;
}
.panel .panel-body.no-padding {
  padding: 0;
}
.panel .panel-body.padding-horizontal {
  padding: 0 15px;
}
.panel .panel-body.no-padding-bottom {
  padding-bottom: 0;
}
.panel .panel-footer {
  background: white;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  border-color: #e1e6ef;
}
.panel.panel-default {
  border: 1px solid #e1e6ef;
}
.panel.panel-default .panel-heading {
  border-color: #e1e6ef;
  background: #fcfcfd;
}
.panel.panel-default .panel-footer {
  border-color: #e1e6ef;
  background: #f2f4f8;
}
.panel.panel-primary {
  border-color: #20a8d8;
}
.panel.panel-primary .panel-heading {
  background: #5dc3e7;
  border-color: #20a8d8;
}
.panel.panel-primary .panel-footer {
  border-color: #20a8d8;
  background: #5dc3e7;
  color: white;
}
.panel.panel-success {
  border-color: #79c447;
}
.panel.panel-success .panel-heading,
.panel.panel-success .panel-footer {
  background: #a3d781;
  border-color: #79c447;
  color: #284315;
}
.panel.panel-info {
  border-color: #67c2ef;
}
.panel.panel-info .panel-heading,
.panel.panel-info .panel-footer {
  background: #acdef6;
  border-color: #67c2ef;
  color: #106894;
}
.panel.panel-warning {
  border-color: #fabb3d;
}
.panel.panel-warning .panel-heading,
.panel.panel-warning .panel-footer {
  background: #fcd588;
  border-color: #fabb3d;
  color: #815703;
}
.panel.panel-danger {
  border-color: #ff5454;
}
.panel.panel-danger .panel-heading,
.panel.panel-danger .panel-footer {
  background: #ffa1a1;
  border-color: #ff5454;
  color: #a10000;
}
/* Wizard
=================================================================== */
.wizard-type1 {
  margin-top: -55px;
}
.wizard-type1 .progress {
  height: 4px;
  margin: 1px -15px 0 -15px;
}
.wizard-type1 ul.steps {
  margin: 0 -15px;
  line-height: 39px;
  height: 39px;
  background: #f5f7fa;
  overflow: hidden;
}
.wizard-type1 ul.steps li {
  margin: 0;
}
.wizard-type1 ul.steps li a {
  padding: 0 10px;
  height: 39px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  color: #374767;
  margin: 0;
  font-size: 12px;
}
.wizard-type1 ul.steps li a .badge {
  background-color: #e1e6ef;
  color: #374767;
  margin-right: 5px;
}
.wizard-type1 ul.steps li.active a {
  background: white;
  border-left: 1px solid #e1e6ef;
  border-right: 1px solid #e1e6ef;
  margin: 0 -1px;
}
.wizard-type1 ul.steps li.active a .badge {
  background-color: #20a8d8;
  color: white;
}
.wizard-type1 ul.steps li.complete a {
  background: white;
}
.wizard-type1 ul.steps li.complete a .badge {
  background-color: #79c447;
  color: white;
}
.wizard-type1 .tab-pane {
  padding: 15px 0;
}
.wizard-type1 .actions {
  border-top: 1px solid #e1e6ef;
  background: #f2f4f8;
  margin: 0 -15px -15px -15px;
  padding: 15px;
}
.wizard-type2 {
  margin-top: -55px;
}
.wizard-type2 .progress {
  height: 4px;
  margin: 10px -5px 0 -5px;
}
.wizard-type2 ul.steps {
  margin: 0 -15px;
  line-height: 40px;
  height: 40px;
  overflow: hidden;
}
.wizard-type2 ul.steps li {
  margin: 0;
}
.wizard-type2 ul.steps li a {
  padding: 0 20px;
  height: 40px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  color: #e1e6ef;
  margin: 0;
  font-size: 12px;
  font-weight: 300;
}
.wizard-type2 ul.steps li.active a {
  background: transparent;
  color: #6d84b1;
  border-bottom: 1px solid #6d84b1;
}
.wizard-type2 ul.steps li.complete a {
  background: transparent;
  color: #79c447;
  border-bottom: 1px solid #79c447;
}
.wizard-type2 .tab-pane {
  padding: 15px 0;
}
#overlay {
   display:none;
   position:fixed;
   left:0px;
   top:0px;
   width:100%;
   height:100%;
   /* background:#000;  */
   opacity:0.5;
   z-index:99999;
}

#popup {
   display:none;
   position:fixed;
   left:75%;
   top:6.5%;
   z-index:100000;
   background-color: white;
   height: 10px;
}

.searchBtn{
	background: #fcfcfc url("//s.swiftypecdn.com/assets/embed/embed_mag-d3b2cb82d2a6506302e2f484b27a40de.png") no-repeat scroll 7px 7px;
    border: 1px solid #ccc;
    border-radius: 15px;
    width:30px;
    height: 30px;
}

.btnLoginSubmit {
	border: 2px solid transparent;
	width: 70px;
	margin-top: 3px;
	margin-left: 4px;
	margin-right: 10px;
}
.compliancetext {
    font-family: 'Open Sans';
	font-weight: 600;
	font-size: 15px;
	color: #3f5873;
    margin: 0 35px;
    text-align: justify;
}
.flexcenter {
	display: flex;
	justify-content: center;
	align-items: center;
}
.labelStyle {
	font: bold 1.2em "Trebuchet MS", Arial, Helvetica, sans-serif;
    color: #2a5996;
}
.nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.capstooltip {
    position: relative;
    display: inline-block;
}

.capstooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.capstooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}